.hover-orange:hover {
  cursor: pointer;
  color: #fa7005;
}

.hover:hover {
  cursor: pointer;
}

.document-text {
  font-size: medium;
}